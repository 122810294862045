<template>
  <div class="position-relative">
    <!-- Display total -->
    <div v-if="totalSurface" class="total-area text-dark-grey">
      {{ cropfields.length }} {{ $t("cropfields.list.countingElements") }} ({{ totalSurface }} ha)
    </div>

    <b-row
      :class="['text-infos', 'mb-1',
        !cropfield.done && 'not-started',
        cropfield.done && cropfield.remainingSurface > 0 && 'started',
        cropfield.done && 'clickable'
      ]"
      @click="gotoActivity(cropfield.activity)"
      v-for="cropfield in cropfields"
      :key="cropfield.id" no-gutters>
      <b-col cols="7">
        <div class="font-weight-bold">
          <s v-if="cropfield.done">{{ cropfield.full_name }}</s>
          <div v-else>{{ cropfield.full_name }}</div>
        </div>

        <div>
          {{ cropfield.crop_name }}
        </div>
      </b-col>
      <b-col cols="5" class="text-right">
        <i>{{ cropfield.farm_name }}</i>
      </b-col>
      <b-col cols="12" class="text-right" v-if="cropfield.surfaceToWork && parseFloat(cropfield.surfaceToWork) != cropfield.cropField_surface">
        {{ $t("components.EditCropfields.surface-to-work") }} : {{ cropfield.surfaceToWork }} ha
      </b-col>
      <b-col cols="12" class="text-right" v-if="cropfield.done && cropfield.remainingSurface > 0">
        {{ $t("components.EditCropfields.remaining_surface") }} : {{ cropfield.remainingSurface }} ha
      </b-col>

      
      <b-col cols="12">
        <!-- inputs list for the cropfield -->
        <CropfieldInputs
          v-if="cropfield.inputs && cropfield.inputs.length > 0"
          storeEditAction="tour/setEditedEntryValue"
          storeReadGetter="tour/currentEntry"
          storeEditedGetter="tour/editedEntry"
          inputFormPath="tour/inputForm"
          :showVolumes="true"
          :cropfieldId="cropfield.cropField"
          class="mt-2"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";

import { mapGetters } from "vuex";

import ActivityImage from "@/components/activity/list/ActivityImage";
import CropfieldInputs from "@/components/commons/details/CropfieldInputs.vue"

export default {
  components: {
    ActivityImage,
    CropfieldInputs
  },
  computed: {
    ...mapGetters({
      currentTour: "tour/currentEntry",
    }),
    cropfields() {
      return this.currentTour.cropFields;
    },
    totalSurface() {
      return _.round(this.cropfields?.map((c) => c.surfaceToWork ? parseFloat(c.surfaceToWork) : c.cropField_surface).reduce((a, b) => a + b, 0), 2);
    },
  },
  methods: {
    /**
     * Affiche le détail d'une activité
     * @param activityId 
     */
    gotoActivity(activityId) {
      if (!activityId) return;
      this.$router.push({ name: "activity.details", params: { activity_id: activityId, goBackOnClose: true } });
    }
  }
};
</script>

<style lang="scss" scoped>
.total-area {
  position: absolute;
  top: -1.3rem;
  right: 0;
  font-size: 0.8rem;
}

.started {
  background: $warning-background;
}

.not-started {
  background: $background-blue-color;
}

.clickable {
  cursor: pointer;
}
</style>
