<template>
  <div>
      <!-- Message if no inputs to show -->
      <div v-if="inputs == 0" class="text-activity-notes enpty-attr text-left">
        {{ $t("components.Inputs.no-input") }}
      </div>

      <div v-else>
        <div class="mb-2" v-for="input in inputs" v-bind:key="input.id">
          <!-- display input details if : the cropfield input dose is different from the root input dose
          -->
          <div v-if="input.dose != getRootInputField(input.inputsStock, 'dose')">
            <!-- input stock infos -->
            <b-row class="px-0 m-0 br-bottom-0 bg-white text-infos">
              <b-col cols="8" class="font-weight-bold">
                {{ getRootInputField(input.inputsStock, "inputsStock_type") + " - " + getRootInputField(input.inputsStock, "inputsStock_name") }}
              </b-col>
              <b-col cols="4" class="text-right">{{ input.quantity || input.dose }} {{ getRootInputField(input.inputsStock, "doseUnit_unit") }}</b-col>
            </b-row>
            
            <!-- input stock volumes details -->
            <b-row class="px-0 m-0 br-top-0 bg-grey text-infos" v-if="showVolumes">
              <b-col cols="5" class="text-dark-grey">{{ $t("components.Inputs.volume-to-use") }}</b-col>
              <b-col cols="7" class="text-right">({{ (input.quantity || input.dose) }} x {{ currentCropfieldSurface }}) = {{ round((input.quantity || input.dose) * currentCropfieldSurface, 2) }} {{ getRelatedDoseUnit(getRootInputField(input.inputsStock, "doseUnit")) }}</b-col>
            </b-row>
          </div>
        </div>

        <!-- input stock total volume -->
        <b-row class="px-0 m-0 mb-1 bg-grey text-infos" v-if="showMixtureVolumes">
          <b-col cols="6" class="text-dark-grey">{{ $t("components.Inputs.mixture-volume") }}</b-col>
          <b-col cols="6" class="text-right">{{ mixtureVolume }} L/ha</b-col>
          <b-col cols="6" class="text-dark-grey">{{ $t("components.Inputs.total-volume") }}</b-col>
          <b-col cols="6" class="text-right">({{ mixtureVolume }} x {{ currentCropfieldSurface }}) = {{ round(mixtureVolume * currentCropfieldSurface, 2) }} L</b-col>
        </b-row>
      </div>
  </div>
  
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

export default {
  mixins: [DetailUpdateMixin],  
  name: "cropfield-inputs",
  props: {
    showVolumes: {
      type: Boolean,
      default: false
    },
    /**
     * not empty if the inputs are related to a cropfield
     */
    cropfieldId: {
      type: Number
    }
  },
  computed: {
    ...mapGetters({
      doseUnits: "doseUnits"
    }),
    cropfields() {
      return this.currentEntry.cropFields;
    },
    /**
     * inputs related to the cropfieldId
     */
    inputs() {
      return this.currentEntry.cropFields?.find(c => c.cropField == this.cropfieldId)?.inputs || []
    },
    /**
     * inputs by default, at the root of the entry
     */
    rootInputs() {
      return this.currentEntry.inputs || []
    },
    currentCropfield() {
      return this.cropfields.find(e => e.cropField == this.cropfieldId)
    },
    currentCropfieldSurface() {
      return parseFloat(this.currentCropfield.surfaceToWork || this.currentCropfield.cropField_surface)
    },
    /* when do we show mixture (bouillie) volumes ? */
    showMixtureVolumes() {
      return this.showVolumes // the showVolumes mode is activated
        && this.currentEntry.activityType_code == "pulve" // we are in pulve family
        && this.inputs.length > 0 // there are inputs
        && Number.isFinite(this.mixtureVolume) && this.mixtureVolume !== 0  // there is a mixture volume value
        && this.mixtureVolume != this.currentEntry.mixtureVolume // it is a specific mixture volume
    },
    mixtureVolume() {
        return this.currentCropfield.mixtureVolume || 0
    }
  },
  mounted() {
  },
  methods: {
    round: _.round,
    /**
     * an helper to get the value of a field on the root inputs
     * because cropfield inputs do not have always all the data need for display
     */
    getRootInputField(inputId, field) {
      return this.rootInputs.find(i => i.inputsStock == inputId)?.[field]
    },

    getRelatedDoseUnit(unit_id) {
      return this.doseUnits.find(d => d.id == unit_id)?.relatedUnit_unit
    },
  }
};
</script>

<style lang="scss" scoped>
/**
 * Overload subtitle-
 */
.overload-subtitle {
  margin: 0px 0px 0px 0px;
}

/**
 * Color text for empty list.
 */
.enpty-attr {
  text-align: center;
  color: $danger-color;
}
</style>
